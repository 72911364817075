<template>
    <div>
        <b-card>
            <app-view-table :title="trans('location',3)" :loading="loading_list" :isTitleTotal="true" :filters.sync="filters" :items="items" :fields="fields" :filterOptions="location_status_list" :isSearch="true" :isFilterOptions="true" :customColumns="customColumns">
                <template v-slot:menu-items="cell">
                    <b-dropdown-item @click=" editLocation(cell.data.item)">{{trans('change',175)}}</b-dropdown-item>
                </template>

                <template v-slot:is_support="cell">
                    {{cell.data.item.is_support == 1 ? trans('yes-option',3) : trans('no-option',3)}}
                </template>

                <template v-slot:is_student="cell">
                    {{cell.data.item.student_recruitment == 1 ? trans('yes-option',3) : trans('no-option',3)}}
                </template>

                <template v-slot:borrow="cell">
                    {{cell.data.item.borrow == 1 ? trans('yes-option',3) : trans('no-option',3)}}
                </template>

                <template v-slot:buttons>
                    <app-button type="primary" @click="locationAdd()">{{trans('add-location',175)}}</app-button>
                </template>
            </app-view-table>
        </b-card>  

        <settings-offices-locations-add v-if="mLocationAdd.show" :show.sync="mLocationAdd.show" :result.sync="mLocationAdd.result" :args="mLocationAdd.args" :filters.sync="filters"  :return.sync="items" />
        <settings-offices-locations-edit v-if="mLocationEdit.show" :show.sync="mLocationEdit.show" :result.sync="mLocationEdit.result" :args="mLocationEdit.args" :filters.sync="filters" :return.sync="items" />

    </div>
</template>

<script>
import axios from "@axios";
import SettingsOfficesLocationsAdd from "./SettingsOfficesLocationsAdd.vue";
import SettingsOfficesLocationsEdit from "./SettingsOfficesLocationsEdit.vue";

export default {
    components: { 
        SettingsOfficesLocationsAdd,
        SettingsOfficesLocationsEdit,
    },

    mounted() {
            this.onCreateThis();
    },

    data() {
        return {
            mLocationAdd: { show: false, args: { id_office_data_main: null }, result: 0, return: null },
            mLocationEdit: { show: false, 
                            args: {id_office_data_location: null, 
                                    location_data:{
                                        location_name:null,
                                        status: null,
                                        phone_prefix: '+31',
                                        phone_number: null,
                                        email: null,
                                        is_student: null,
                                        is_support: null,
                                        is_borrow: null,
                                        address: {
                                                type: 1,
                                                post_code: null, 
                                                house_number: null, 
                                                house_number_additional:null,
                                                city:null,
                                                street:null,
                                                latitude: null,
                                                longitude: null,
                                                country:null,
                                            },
                                        schedule_email: null,
                                        is_schedule_email: null
                                    }
                                }, result: 0, return: null },
            loading_list: false,
            id_office_data_main: this.$route.params.id_office_data_main,
            location_status_list: [
              { value: 1, name: this.trans('active',3) },
              { value: 2, name: this.trans('blocked',3) }
            ],
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                page: 1,
                per_page: 10,
                search: "",
                list_type: 1,
                changed: 0,
            },
            fields: [
                { key: "location_name", label: this.trans('full-name',3), thStyle: { width: "100%" }, visible: true},
                { key: "contact_person_phone", label: this.trans('phone-number',182), visible: true, thStyle: { "min-width": "200px", "max-width": "200px" } },
                { key: "email", label: this.trans('settings-location-contact-person-email', 207), visible: true, thStyle: { "min-width": "220px", "max-width": "220px" } },
                { key: "schedule_email_show", label: this.trans('settings-location-settings-email', 207), visible: true, thStyle: { "min-width": "220px", "max-width": "220px" } },
                { key: "address", label: this.trans('address-details', 3), visible: true, thStyle: { "min-width": "300px", "max-width": "300px" } },
                { key: "is_support", label: this.trans('support', 207), visible: true, thStyle: { "min-width": "100px", "max-width": "100px" } },
                { key: "is_student", label: this.trans('fl-student', 176), visible: true, thStyle: { "min-width": "100px", "max-width": "100px" } },
                { key: "borrow", label: 'Doorleen' , visible: true, thStyle: { "min-width": "100px", "max-width": "100px" } },
            ],
            items: [],
            customColumns: ["is_support", "is_student", "borrow"],
        };
    },

    methods: {
        getList: function() {
            this.loading_list = true;
            axios
                .post("settings/offices/getLocations", {
                    filters: JSON.stringify(this.filters),
                    id_office_data_main: this.id_office_data_main
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                });
        },

        locationAdd() {
          this.mLocationAdd.args.id_office_data_main = this.id_office_data_main;
          this.mLocationAdd.show = true;
        },

        editLocation: function(data) {
            this.mLocationEdit.args.id_office_data_location = data.id_office_data_location;
            this.mLocationEdit.args.id_office_data_main = this.id_office_data_main;
            this.mLocationEdit.args.location_data.is_support = data.is_support ? data.is_support : 0;
            this.mLocationEdit.args.location_data.is_student = data.student_recruitment ? data.student_recruitment : 0;
            this.mLocationEdit.args.location_data.is_borrow = data.borrow ? data.borrow : 0;
            this.mLocationEdit.args.location_data.location_name = data.location_name;
            this.mLocationEdit.args.location_data.status = data.status;
            this.mLocationEdit.args.location_data.phone_prefix = data.phone_prefix == null ? '+31' : data.phone_prefix;
            this.mLocationEdit.args.location_data.phone_number = data.phone_number;
            this.mLocationEdit.args.location_data.email = data.email;
            this.mLocationEdit.args.location_data.schedule_email = data.schedule_email;
            this.mLocationEdit.args.location_data.is_schedule_email = data.is_schedule_email;
            this.mLocationEdit.args.location_data.address.type = data.type;
            this.mLocationEdit.args.location_data.address.post_code = data.post_code;
            this.mLocationEdit.args.location_data.address.house_number = data.house_number;
            this.mLocationEdit.args.location_data.address.house_number_additional = data.number_additional;
            this.mLocationEdit.args.location_data.address.street = data.street;
            this.mLocationEdit.args.location_data.address.city = data.city;
            this.mLocationEdit.args.location_data.address.latitude = data.latitude;
            this.mLocationEdit.args.location_data.address.longitude = data.longitude;
            this.mLocationEdit.args.location_data.address.country = {name: data.country_name, value: data.id_country_data_main}
            this.mLocationEdit.show = true;
        },

        onCreateThis() { 

            this.getList();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.list_type',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });
        },
    },
};
</script>

<style scoped></style>
