<template>
    <b-modal ref="modal-add" v-model="computedShow" no-close-on-backdrop :title="trans('change-location',3)">
        <ValidationObserver ref="form" >

            <app-divider-modal :text="trans('location',3)" />

            <app-row-left-label :label="trans('settings-location-name',207)">
                <app-input v-model="cArgs.location_data.location_name" validatorName="Naam locatie" validatorId="name" validatorRules="required" mask="lettersE1-25" />
            </app-row-left-label>

            <app-row-left-label :label="trans('blocked',3)">
                <app-check-box v-model="cArgs.location_data.status" value="1" unchecked-value="0"/>
            </app-row-left-label>

            <app-row-left-label :label="trans('support', 207)">
                <app-check-box v-model="cArgs.location_data.is_support" value="1" unchecked-value="0"/>
            </app-row-left-label>

            <app-row-left-label :label="trans('fl-student', 176)">
                <app-check-box v-model="cArgs.location_data.is_student" />
            </app-row-left-label>

            <app-row-left-label label="Doorleen">
                <app-check-box v-model="cArgs.location_data.is_borrow" />
            </app-row-left-label>

            <app-divider-modal :text="trans('contact-details',182)" :isLine="true"/>

            <app-row-left-label :label="trans('phone-number',182)">
                <app-phone :prefix.sync="cArgs.location_data.phone_prefix" :phone.sync="cArgs.location_data.phone_number" :isRequired="true"/>
            </app-row-left-label>
                
            <app-row-left-label :label="trans('email',182)">
                <app-email :email.sync="cArgs.location_data.email" validatorName="Email" validatorRules="required" type="person" />
            </app-row-left-label>

            <app-divider-modal :text="trans('address-details',3)" :isLine="true"/>

            <app-address ref="address_control" :address.sync="cArgs.location_data.address"/>

            <app-divider-modal text="Mail instellingen" :isLine="true"/>
            
            <app-row-left-label :label="trans('email',182)">
                <app-email ref="schedule_email" :email.sync="cArgs.location_data.schedule_email" @checked="emailChecked" @input="emailChange" />
            </app-row-left-label>

            <app-row-left-label label="Planning mails ontvangen">
                <app-check-box v-model="cArgs.location_data.is_schedule_email" :disabled="cArgs.location_data.schedule_email == null || cArgs.location_data.schedule_email == '' || !is_email_valid"/>
            </app-row-left-label>

         </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="editLocation()" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import AppAddress from '@core/components/Address/AppAddress.vue';
import AppPhone from '@core/components/AppPhone.vue';
import AppEmail from '@core/components/AppEmail.vue';

export default {
    components: {
        ValidationObserver,
        AppEmail,
        AppAddress,
        AppPhone
    },

    props: ["show", "result", "args", "return", "filters"],

    created(){
        this.checkEmail(this.cArgs.location_data.schedule_email == null ? '' : this.cArgs.location_data.schedule_email);
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set() { this.$emit('update:args', false) }
        }
    },


    data() {
        return {
            loading: false,
            is_email_valid: null,
            email_regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        };
    },

    methods: {
        editLocation() {

            this.$refs.form.validate().then((result) => {
                if (!result) { 
                    return false;
                }else{
                    if(!this.$refs.address_control.address.street && this.cArgs.location_data.address.post_code > 0){
                        return false;
                    }else{
                        this.loading = true;
                        axios
                            .post("settings/offices/changeLocations", {
                                id_office_data_location: this.args.id_office_data_location,
                                location_data: JSON.stringify(this.cArgs.location_data),
                                filters: JSON.stringify(this.filters),
                                id_office_data_main: this.args.id_office_data_main
                            })
                            .then((res) => {
                                if(res.data == -1){
                                    this.$refs.form.setErrors({name: this.trans('settings-name-already-taken',208)});
                                    this.loading=false;
                                    return false;
                                }
                                this.$emit("update:return", res.data.items);
                                this.$emit("update:show", false);
                                this.loading = false;
                            })
                            .catch((error) => {

                                console.error(`error during request: ${error}`);
                            });
                    }
                }
            })
            
           
        },

        checkEmail(event){
            this.is_email_valid = event.match(this.email_regex);
        },

        emailChecked(){
            this.is_email_valid = this.cArgs.location_data.schedule_email.match(this.email_regex);
        },

        emailChange(event){
            this.cArgs.location_data.is_schedule_email = "0";
            this.is_email_valid = false;
        },

        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>

<style scoped></style>
